import React from 'react';

export const Ledger = () => (
  <svg
    height='30'
    viewBox='0 0 42 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='wallet-icon body'
  >
    <path
      d='M34.4876 0.0413742H15.7717V25.323H41.0412V6.58293C41.0412 2.9933 38.091 0.0413742 34.5081 0.0413742C34.5019 0.0413742 34.4937 0.0413742 34.4876 0.0413742V0.0413742Z'
      fill='#ffffff'
    ></path>
    <path
      d='M9.93712 0.0413742H6.71166C3.12874 0.0413742 0.178574 2.99536 0.178574 6.58293V9.81256H9.93712V0.0413742Z'
      fill='#ffffff'
    ></path>
    <path
      d='M0.178574 15.6547H9.93712V25.4259H0.178574V15.6547Z'
      fill='#ffffff'
    ></path>
    <path
      d='M31.3648 40.9569H34.5903C38.1753 40.9569 41.1234 38.0029 41.1234 34.4153C41.1234 34.4092 41.1234 34.401 41.1234 34.3948V31.268H31.3648V40.9569Z'
      fill='#ffffff'
    ></path>
    <path
      d='M15.7717 31.268H25.5302V41.0392H15.7717V31.268Z'
      fill='#ffffff'
    ></path>
    <path
      d='M0.178574 31.268V34.4976C0.178574 38.0852 3.12874 41.0392 6.71166 41.0392H9.93712V31.268H0.178574Z'
      fill='#ffffff'
    ></path>
  </svg>
);
